import './src/styles/global.css'
//import wrapWithProvider from "./wrap-with-provider"
//export const wrapRootElement = wrapWithProvider

import React from "react"
//import { GoogleOAuthProvider } from '@react-oauth/google';
//import { FronteggProvider } from '@frontegg/react';

// const contextOptions = {
//   baseUrl:  process.env.GATSBY_FRONTEGG_BASE_URL ,
//   clientId: process.env.GATSBY_FRONTEGG_CLIENT_ID
// };


// const authOptions = {
//   // keepSessionAlive: true // Uncomment this in order to maintain the session alive
//  };
 
// eslint-disable-next-line react/display-name,react/prop-types
const wrapRootElement = ({ element }) => {
 // if (typeof document !== "undefined") {
  return (
    <>
     {/* <GoogleOAuthProvider clientId={process.env.GATSBY_GOOGLE_CLIENTID}> */}
      {/* <FronteggProvider contextOptions={contextOptions} hostedLoginBox={true} authOptions={authOptions}> */}
     
      {element}
     
      {/* </FronteggProvider> */}
       {/* </GoogleOAuthProvider> */}
      </>
  )
  // } else {
  //   return (
  //     <GoogleOAuthProvider clientId={process.env.GATSBY_GOOGLE_CLIENTID}>
        
  //       {element}
        
  //       </GoogleOAuthProvider>
  //   )
  // }

}

export {wrapRootElement}