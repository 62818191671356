
exports.onClientEntry = function (_, pluginOptions) {
  //console.log("We've started! onClientEntry")
}

exports.onInitialClientRender = function (_, pluginOptions) {
  //console.log("We've started! onInitialClientRender")



  const hotjarId = pluginOptions.id;
  const hotjarSv = pluginOptions.sv;
  const delayLoad = pluginOptions.optimize;

  const script = document.createElement("script");

  script.innerHTML = ` (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:${hotjarId},hjsv:${hotjarSv}};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`;


  //console.log(script)
  const appendScript = () => {
    document.body.appendChild(script);
  };

  //appendScript();
  //return
  if (!delayLoad) {
    appendScript();
  } else {
    setTimeout(() => {
      //console.log('do', window["requestIdleCallback"])
      /* window["requestIdleCallback"]
        ? window.requestIdleCallback(appendScript)
        : */ appendScript();
    }, 10000);
  }
}
